<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-group" />
      <h5 class="d-inline ml-2">Couriers</h5>
      <div class="card-header-actions">
        <CPagination
          class="cl-pagination"
          v-if="pages > 1"
          align="center"
          :dots='false'
          :pages="pages"
          :active-page.sync="activePage"
        />
      </div>
    </CCardHeader>

    <CCardBody>
      <!-- Filters -->
      <CCard class="filters" accent-color="warning">
        <CCardBody class="p-2">
          <div class="row">
            <!-- Hub Filter -->
            <div class="col-xl mb-2 mb-xl-0">
              <v-select
                class="v-select-filter"
                placeholder="Hubs.."
                label="name"
                v-model="selectedHubs"
                :options="hubs"
                :searchable="false"
                :reduce="hub => hub.id"
                multiple
                @input="inputFilter"
              >
                <template v-slot:option="hub">
                  <strong>{{ hub.zip }}</strong> -
                  <span>{{ hub.name }} </span>
                </template>
              </v-select>
            </div>

            <!-- Status Filter -->
            <div class="col-xl mb-2 mb-xl-0 pl-xl-0">
              <v-select
                class="v-select-filter"
                placeholder="Status.."
                v-model="selectedStatus"
                :options="[
                  { value: 'active', label: 'Is Working' },
                  { value: 'inactive', label: 'Is Not Working' },
                ]"
                :searchable="false"
                :reduce="o => o.value"
                multiple
                @input="inputFilter"
              >
              </v-select>
            </div>

            <!-- Contract Filter -->
            <div class="col-xl mb-2 mb-xl-0 pl-xl-0">
              <v-select
                class="v-select-filter"
                placeholder="Contract model.."
                v-model="selectedContractModel"
                :options="[
                  { value: 'no_active_contract', label: '- No active contract -' },
                  { value: 'per_order', label: 'Per order' },
                  { value: 'hourly', label: 'Hourly' }
                ]"
                :reduce="option => option.value"
                :searchable="false"
                @input="inputFilter"
              />
            </div>

            <!-- Search -->
            <div class="col-xl mb-0 pl-xl-0">
              <CInput
                class="mb-0 search"
                type="search"
                placeholder="Search for something else ..."
                v-model="search"
                @input="searchFilter"
              />
            </div>
          </div>
        </CCardBody>
      </CCard>

      <CDataTable
        striped
        hover
        :items="loadedItems"
        :fields="fields"
        :sorter="{ external: true, resetable: true }"
        :itemsPerPageSelect="{
          external: true,
          values: [5, 15, 25, 50, 100, 250, 500],
        }"
        :items-per-page.sync="itemsPerPage"
        :loading="loading"
        :noItemsView="{
          noResults: 'No filtering results are available!',
          noItems: 'No courier found!',
        }"
        @update:sorter-value="sorterValue"
        @pagination-change="paginationChange"
      >
        <!-- User Icon -->
        <template #image_icon="{item}">
          <td>
            <CImg
              :src="item.image_icon || item.gravatar"
              class="c-avatar-img"
              style="width: 36px; height: 36px"
              placeholderColor="lightgray"
            />
          </td>
        </template>

        <!-- Name -->
        <template #first_name="{item}">
          <td>
            <div class="vertical-center flex-gap-1 mb-2">
              <span v-if="item.settings?.is_dispatcher" class="dot bg-secondary" v-c-tooltip="{ content: 'Dispatcher' }">👔</span>
              <span v-else-if="item.settings?.is_subordinate" class="dot bg-secondary" v-c-tooltip="{ content: `#${item.detail.parent_id}'s subordinate` }">👷</span>
              <strong>{{ item.first_name }}</strong><small class="text-muted">#{{ item.id }}</small>
            </div>
            <div class="small"><CIcon name="cil-at" class="mr-1" />{{ item.email }}</div>
            <div class="small"><CIcon name="cil-phone" class="mr-1" />{{ item.mobile }}</div>
          </td>
        </template>

        <!-- Contract model -->
        <template #contract_model="{item}">
          <td>
            <div v-if="item.active_contract">
              <span>{{ item.active_contract.contract_model | normalizeSentence | uppercase }} / </span>
              <span v-if="item.active_contract.employment_type">{{ item.active_contract.employment_type | normalizeSentence | uppercase }}</span>
              <span v-else class="flash-it">❓</span>
            </div>
            <span v-else class="flash-it text-danger">❌ No active contract!</span>
          </td>
        </template>

        <!-- Vehicle -->
        <template #vehicle="{item}">
          <td>
            <div v-if="item.vehicle" class="d-flex align-items-center flex-gap-2">
              <MIcon :tag="item.vehicle.mode" />
              <span class="text-uppercase">{{ item.vehicle.license_plate }}</span>
            </div>
          </td>
        </template>

        <!-- Hubs -->
        <template #hubs="{item}">
          <td class="p-0">
            <CIcon v-if="item._classes.includes('table-warning')" name="cil-warning" size="lg" />
            <CBadge class="mx-1" color="secondary" v-for="hub in item.hubs" :key="hub.id">{{ hub.name }}</CBadge>
          </td>
        </template>

        <!-- Is Working -->
        <template #is_working="{ item, index }">
          <td class="text-center pr-4">
            <!-- Working now -->
            <template v-if="item.status?.is_working_now">
              <!-- On break -->
              <template v-if="item.status?.on_break">
                <CSpinner v-if="item.updating" size="sm" color="warning" class="mb-1" />
                <span v-else class="dot bg-warning" role="button" @click="forceEndBreak(item, index)"></span>
              </template>

              <!-- Not on break -->
              <template v-else>
                <!-- Per Order Contract Active -->
                <template v-if="item.active_contract?.contract_model === 'per_order'">
                  <CSpinner v-if="item.updating" size="sm" color="success" class="mb-1" />
                  <span v-else class="dot bg-success" role="button" @click="endWorkingStatus(item, index)"></span>
                </template>

                <CLink v-else :to="{ name: 'Edit Courier', params: { id: item.id }, query: { tab: 4 } }">
                  <span class="dot bg-success"></span>
                </CLink>
              </template>
            </template>

            <!-- Not working now -->
            <template v-else>
              <!-- Per Order Contract Active -->
              <template v-if="item.active_contract?.contract_model === 'per_order'">
                <CSpinner v-if="item.updating" size="sm" color="danger" class="mb-1" />
                <span v-else class="dot bg-danger" role="button" @click="startWorkingStatus(item, index)"></span>
              </template>

              <CLink v-else :to="{ name: 'Edit Courier', params: { id: item.id }, query: { tab: 4 } }">
                <span class="dot bg-danger"></span>
              </CLink>
            </template>
          </td>
        </template>

        <!-- On Order -->
        <template #on_order="{ item }">
          <td class="">
            <div class="vertical-center separate-items">
              <!-- DISPATCHER -->
              <div v-if="item.dispatcher_detail?.has_assignable_orders">
                <span
                  :class="{
                    'dot highlighted-dot text-white font-weight-bold': true,
                    'bg-info': item.dispatcher_detail.assigned_orders_count === item.dispatcher_detail.assignable_orders_count,
                    'bg-warning': item.dispatcher_detail.assigned_orders_count < item.dispatcher_detail.assignable_orders_count
                  }"
                  v-c-tooltip="{ content: `${item.dispatcher_detail.assigned_orders_count}/${item.dispatcher_detail.assignable_orders_count} order assigned.` }">
                  {{ item.dispatcher_detail.assignable_orders_count }}
                </span>
              </div>

              <!-- COURIER -->
              <div v-if="item.status?.on_order" class="vertical-center justify-content-center separate-items pl-0">
                <div v-for="(order, index) in item.active_orders" :key="index" class="vertical-center justify-content-center flex-gap-1">
                  <CLink :to="{ name: 'Order Details', params: { id: order.id } }"
                    v-c-tooltip="{ content: `o#${order.id}`, placement: 'top' }"
                    style="height: 20px;"
                  >
                    <span class="dot bg-success"></span>
                  </CLink>

                  <CLink v-if="order.revocable"
                    class="align-self-baseline"
                    v-c-tooltip="{ content: 'Transfer or revoke order', placement: 'top' }"
                    @click="transferOrRevokeOrder(order)"
                  >
                    <CIcon name="cil-transfer" />
                  </CLink>
                </div>
              </div>
              <div class="vertical-center" v-else><span class="dot"></span></div>
            </div>
          </td>
        </template>

        <!-- Work Times Column -->
        <template #work-times="{ item }">
          <td>
            <div class="d-flex align-items-center">
              <!-- Weekly Work Times -->
              <CButton size="sm" color="info" variant="ghost" shape="pill" class="mr-2" @click="showWeeklyWorkTimes(item)">
                <CIcon name="cil-calendar" />
              </CButton>

              <!-- Today Work Times -->
              <ul class="list-unstyled today-work-times">
                <template v-for="time in item.today_work_times?.times">
                  <li>
                    <div class="d-flex justify-content-between align-items-start">
                      <span>{{ time.type | ucfirst }}</span>
                      <div class="text-right">
                        <template v-if="time.end_time">
                          <span>{{ moment(time.start_time).format('HH:mm') }}</span>
                          <CIcon name="cil-arrow-right" class="mx-1" />
                          <span>{{ time.end_time }}</span>
                        </template>
                        <template v-else>
                          <span class="d-block">{{ moment(time.start_time).format('D MMM HH:mm') }}</span>
                          <em class="text-primary">(Ongoing)</em>
                        </template>
                      </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-end ml-2 mt-1" v-if="time.type === 'hourly'">
                      <template v-if="time.start_time_confirmed">
                        <span class="mr-1">✅</span>
                        <span>{{ time.start_time_confirmed }}</span>
                      </template>
                      <template v-else>
                        <span class="mr-1">❌</span>
                        <em class="text-danger">Not confirmed</em>
                      </template>
                    </div>
                  </li>
                </template>
                <li v-if="item.today_work_times?.times.length === 0" class="text-danger small font-italic">
                  No work hours found for today!
                </li>
              </ul>
            </div>
          </td>
        </template>
      </CDataTable>

      <mc-spinner :opacity="0.8" v-show="loading" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />

      <TransferOrRevokeOrder
        :courierId="selectedOrder?.courier_id"
        :dispatcherId="selectedOrder?.dispatcher_id"
        :order="$globalFunc.extractKeys(selectedOrder, ['id', 'roDesiredDelivireyTime', 'roAproxDeliveryDateToCourier', 'roAddress', 'roCity', 'roPostal_code', 'roCountryId'])"
        :restaurant="$globalFunc.extractKeys(selectedOrder?.restaurant, ['id', 'restaurant_postcode'])"
        @transferred="getAllItems"
      />

      <WeeklyWorkTimes :courier="selectedCourier" @close="selectedCourier = null" />
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import _debounce from 'lodash/debounce';
import TransferOrRevokeOrder from "@/components/Courier/TransferOrRevokeOrder";
import WeeklyWorkTimes from "@/views/Courier/Dashboard/WeeklyWorkTimes";

export default {
  name: "CourierList",
  components: {
    TransferOrRevokeOrder,
    WeeklyWorkTimes
  },
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "image_icon", label: "", sorter: false,  _style: "min-width: 60px; width: 60px;" },
        { key: "first_name", label: "Name", sorter: false, _style: "min-width: 200px;" },
        { key: "contract_model", sorter: false, _style : "min-width: 160px;width: 200px;" },
        { key: "vehicle", sorter: false, _style : "min-width: 160px;width: 200px;" },
        { key: "hubs", label: "Hubs", sorter: false, _style: "min-width: 160px;" },
        { key: "is_working", label: "Is Working", sorter: false,  _style: "min-width: 60px; width: 60px; text-align: center;" },
        { key: "work-times", sorter: false,  _style: "min-width: 240px; width: 240px; text-align: center;" },
        { key: "on_order", sorter: false,  _style: "min-width: 60px; width: 60px;" },
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      direction: null,
      search: null,

      hubs: [],
      selectedOrder: null,
      selectedCourier: null,

      loading: false,

      // Element Cover Spinner
      mcSpinnerMarginTop: 0,
    };
  },

  beforeMount() {
    this.searchFilter = _debounce(() => {
      this.getAllItems();
    }, 1000);
  },

  async mounted() {
    this.activePage =  this.queryPage;

    await Promise.all([
      this.getAllItems(),
      this.getHubs(),
    ]);

    window.onscroll = () => {
      this.mcSpinnerMarginTop = document.documentElement.scrollTop;
    }
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage)
        this.$router.push({ name: 'Couriers', query: { page: this.activePage } });

      this.onTableChange();
    },
  },

  computed: {
    ...mapGetters(["errors"]),
    ...mapState({
      courierFilters: state => state.filters.deliveryService.couriers
    }),
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    queryPage() { return parseInt(this.$route.query.page) || 1; },
    selectedHubs: {
      get() { return this.courierFilters.selectedHubs; },
      set(value) { this.$store.commit('setDeliveryServiceFilter', { key: 'couriers.selectedHubs', value }); }
    },
    selectedStatus: {
      get() { return this.courierFilters.selectedStatus; },
      set(value) { this.$store.commit('setDeliveryServiceFilter', { key: 'couriers.selectedStatus', value }); }
    },
    selectedContractModel: {
      get() { return this.courierFilters.selectedContractModel; },
      set(value) { this.$store.commit('setDeliveryServiceFilter', { key: 'couriers.selectedContractModel', value }); }
    },
  },

  methods: {
    async getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.COURIER.COURIERS.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          'order-by': this.orderBy,
          direction: this.direction,
          hubs: this.selectedHubs,
          status: this.selectedStatus,
          search: this.search,
          'with-trashed': true,
          'contract-model': this.selectedContractModel,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.meta.current_page > items.meta.last_page)
            this.activePage = 1;
          else {
            this.activePage = items.meta.current_page;
            this.pages = items.meta.last_page;
            this.total = items.meta.total;

            this.loadedItems = items.data.map((el) => {
              return {
                ...el,
                updating: false,
                deleting: false,
                _classes: (!el.detail ? 'strikeout disable-item ' : (el.hubs.length === 0 ? 'table-warning ' : ''))
              };
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.direction = item.column ? (item.asc ? "asc" : "desc") : null;
      this.getAllItems();
    },

    async getHubs() {
      this.hubs = JSON.parse(localStorage.getItem('hub-list')) || [];

      await this.$axios
        .get(this.$backend.COURIER.HUBS.GET_LIST)
        .then(({ data }) => {
          this.hubs = data;
          localStorage.setItem('hub-list', JSON.stringify(data));
        })
        .catch(() => this.hubs = [])
    },


    // Filters
    inputFilter() {
      this.getAllItems();
    },

    transferOrRevokeOrder(order) {
      this.selectedOrder = order;
      this.$store.state.transferOrRevokeOrderShow = true;
    },

    async forceEndBreak(courier, index) {
      const confirm = await this.mSwal.fire({
        title: "Are you sure you want to end the courier's break?",
        text: "The courier will be taken off break status.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, end break",
        cancelButtonText: "No, keep on break",
      });

      if (confirm.isConfirmed) {
        const endBreakUrl = this.$backend.COURIER.BREAKS.FORCE_END.replace("{id}", courier.id);
        courier.updating = true;

        try {
          const { data } = await this.$axios.post(endBreakUrl);

          if (data.success) {
            courier.status.on_break = false;
            this.$toast.success("The courier's break has been ended successfully.");
          }
        } catch (error) {
          const errorMessage = typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors;
          this.$toast.error(errorMessage);
        } finally {
          courier.updating = false;
          this.$forceUpdate();
        }
      }
    },

    async endWorkingStatus(courier, index) {
      const confirm = await this.mSwal.fire({
        title: "Are you sure you want to end the courier's working status?",
        text: "The courier will be marked as not working.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, end working status",
        cancelButtonText: "No, keep working",
      });

      if (confirm.isConfirmed) {
        const endWorkingUrl = this.$backend.COURIER.WORKING_HOURS.END.replace("{id}", courier.id);
        courier.updating = true;

        try {
          const { data } = await this.$axios.post(endWorkingUrl);

          if (data.success) {
            courier.status.is_working_now = false;
            this.$toast.success("The courier's working status has been ended successfully.");
          }
        } catch (error) {
          const errorMessage = typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors;
          this.$toast.error(errorMessage);
        } finally {
          courier.updating = false;
          this.$forceUpdate();
        }
      }
    },

    async startWorkingStatus(courier, index) {
      const confirm = await this.mSwal.fire({
        title: "Are you sure you want to start the courier's working status?",
        text: "The courier will be marked as working.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, start working",
        cancelButtonText: "No, keep not working",
      });

      if (confirm.isConfirmed) {
        const startWorkingUrl = this.$backend.COURIER.WORKING_HOURS.START.replace("{id}", courier.id);
        courier.updating = true;

        try {
          const { data } = await this.$axios.post(startWorkingUrl);

          if (data.success) {
            courier.status.is_working_now = true;
            this.$toast.success("The courier's working status has been started successfully.");
          }
        } catch (error) {
          const errorMessage = typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors;
          this.$toast.error(errorMessage);
        } finally {
          courier.updating = false;
          this.$forceUpdate();
        }
      }
    },

    showWeeklyWorkTimes(courier) {
      this.selectedCourier = courier;
      this.$store.commit('set', ['courierWeeklyWorkTimesAsideShow', true]);
    },
  },
};
</script>

<style>
.table th, .table td {
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}

.search input {
  font-size: 1rem !important;
}

.cl-pagination ul {
  margin: 0;
}

ul.today-work-times {
  width: 100%;
  padding-right: 0.675rem;
  margin-bottom: 0;
}

ul.today-work-times li:not(:last-child) {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 5px;
}

</style>
