<template>
  <CRow>
    <CCol sm="12">
      <CourierList />
    </CCol>
  </CRow>
</template>

<script>
import CourierList from "./CourierList";

export default {
  name: "CourierDashboard",
  components: {
    CourierList,
  },
};
</script>
