<template>
  <CSidebar
    aside
    :show="$store.state.courierWeeklyWorkTimesAsideShow"
    @update:show="(val) => $store.commit('set', ['courierWeeklyWorkTimesAsideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
  >
    <CSidebarClose @click.native="$store.commit('toggle', 'courierWeeklyWorkTimesAsideShow')" />

    <!-- Error Alert -->
    <CListGroup class="list-group-accent mt-5" v-show='showErrorAlert'>
      <CListGroupItem class="list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase">
        Error
      </CListGroupItem>

      <CListGroupItem href="#" class="list-group-item-accent-danger list-group-item-divider">
        <div class="c-avatar float-left mr-2">
          <CIcon name="cil-bug" size="xl" class="c-avatar-img text-danger"/>
        </div>
        <div>{{ errorAlertMessage }}</div>
      </CListGroupItem>
    </CListGroup>
    <!-- / Error Alert -->

    <CListGroup class="scrollable list-group-accent mt-5" v-show='!showErrorAlert'>
      <CListGroupItem class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase">
        Weekly Work Times
      </CListGroupItem>

      <template v-for="(item, dIndex) in items">
        <CListGroupItem class="list-group-item-accent-secondary">
          <div class="d-flex justify-content-between align-items-center">
            <strong>{{ formatRelativeDate(item.date) }}</strong>
            <span class="text-muted">{{ formatDate(item.date) }}</span>
          </div>
        </CListGroupItem>

        <template v-for="(time, tIndex) in item.times">
          <CListGroupItem class="list-group-item-divider">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center ml-2">
                <span class="mr-1">{{ getTypeIcon(time.type) }}</span>
                <span>{{ time.type | ucfirst }}</span>
              </div>
              <div class="text-right">
                <template v-if="time.end_time">
                  <span>{{ formatTime(time.start_time) }}</span>
                  <CIcon name="cil-arrow-right" class="mx-1"/>
                  <span>{{ time.end_time }}</span>
                </template>
                <template v-else>
                  <span>{{ time.start_time }}</span>
                  <em class="text-primary ml-2">(Ongoing)</em>
                </template>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-end ml-2 mt-1" v-if="time.type === 'hourly'">
              <template v-if="time.start_time_confirmed">
                <span class="mr-1">✅</span>
                <span>{{ time.start_time_confirmed }}</span>
              </template>
              <template v-else>
                <span class="mr-1">❌</span>
                <em class="text-danger">Not confirmed</em>
              </template>
            </div>
          </CListGroupItem>
        </template>
        <template v-if="item.times.length === 0">
          <CListGroupItem class="list-group-item-divider">
            <em class="text-danger ml-2">No work times found!</em>
          </CListGroupItem>
        </template>
      </template>
    </CListGroup>

    <CElementCover :opacity="0.4" v-show="loading || submitted"/>
  </CSidebar>
</template>

<script>
export default {
  name: "WeeklyWorkTimes",
  props: {
    courier: {
      type: Object,
      default: null
    },
  },
  components: {
  },
  data() {
    return {
      loading: false,
      submitted: false,
      items: [],
      errorAlertMessage: '',
      showErrorAlert: false,
    };
  },
  watch: {
    async reloadParams() {
      this.$store.state.courierWeeklyWorkTimesAsideShow && await this.getWeeklyWorkTimes();
    },
  },
  computed: {
    reloadParams() {
      return [this.$store.state.courierWeeklyWorkTimesAsideShow];
    },
  },

  methods: {
    async getWeeklyWorkTimes() {
      this.items = [];
      this.loading = true;
      await this.$axios.get(this.$backend.COURIER.COURIERS.WEEKLY_WORK_TIMES.replace('{id}', this.courier.id))
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {
          this.errorAlertMessage = error.response.data.message
          this.showErrorAlert = true
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTypeIcon(type) {
      if (type == 'flexible') {
        return '📦';
      } else if (type == 'hourly') {
        return '🕒';
      }
      return '❓';
    },
    formatTime(time) {
      return this.moment(time).format('HH:mm');
    },
    formatDate(date) {
      return this.moment(date).format('DD MMMM YYYY');
    },
    formatRelativeDate(date) {
      return this.moment(date).calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'L'
      });
    }
  }
};
</script>

<style scoped>
.scrollable {
  overflow-y: auto;
  height: 100%;
}
</style>
